import { useQuery, useQueryClient } from 'react-query';
// import { TimeDurationEnum } from 'shared/enums/TimeDurationEnum';
import { procedureService } from 'shared/services/ProcedureService';

export const useProcedure = (procedureID: any) => {
    const queryClient = useQueryClient();
    const { data, isLoading, isError, refetch } = useQuery(
        ['procedure', procedureID],
        () => {
            return procedureService.getProcedureCollection(procedureID);
        },
        {
            onError: (error: Error) => {
                console.log('Error on getting student - ', error);
            },
            initialData: () =>
                queryClient.getQueryData(['procedure', procedureID]),
            enabled: Boolean(procedureID),
            // staleTime: TimeDurationEnum.MINUTE,
            // cacheTime: 0,
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError,
        refetch,
    };
};
