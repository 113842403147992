import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

import ReactPaginate from 'react-paginate';

import { ReactComponent as ArrowRight } from '../../../assets/icons/ArrowRight.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/ArrowLeft.svg';
import { ReactComponent as ArrowFullRight } from '../../../assets/icons/ArrowFullRight.svg';
import { ReactComponent as ArrowFullLeft } from '../../../assets/icons/ArrowFullLeft.svg';
import { ReactComponent as ArrowDropDown } from '../../../assets/icons/ArrowDropDown.svg';

const ITEMS_PER_PAGE = 10;

export type sortableHeader = 'asc' | 'desc';

export interface ITableHeaders {
    title: string;
    sortable?: sortableHeader;
    selected?: boolean;
}

interface ITableRow {
    data: string[];
    actions?: any;
}

interface iTable {
    data: {
        headers: ITableHeaders[];
        row: ITableRow[];
    };
    orderByTableHeader?: (index: number) => void;
}

export default function Table(props: iTable) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState<
        { data: string[]; actions?: any }[]
    >([]);
    const [pageCount, setPageCount] = useState(0);
    const [selectedPage, setSelectedPage] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + ITEMS_PER_PAGE;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(props.data.row.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(props.data.row.length / ITEMS_PER_PAGE));
    }, [itemOffset, props.data.row]);

    // Invoke when user click to request another page.
    const handlePageClick = (event: any) => {
        const newOffset =
            (event.selected * ITEMS_PER_PAGE) % props.data.row.length;
        // console.log(
        //   `User requested page number ${event.selected}, which is offset ${newOffset}`
        // );
        setSelectedPage(event.selected);
        setItemOffset(newOffset);
    };

    const handleBeginingPage = () => {
        setItemOffset(0);
        setSelectedPage(0);
    };

    const handleEndPage = () => {
        setItemOffset(
            ((pageCount - 1) * ITEMS_PER_PAGE) % props.data.row.length
        );
        setSelectedPage(pageCount - 1);
    };

    return (
        <>
            <table className={styles.contentTable}>
                <thead>
                    <tr>
                        {props.data.headers.map((tableHeader, index) => (
                            <th key={index}>
                                <div
                                    className={styles.Header}
                                    style={{
                                        cursor: tableHeader.sortable
                                            ? 'pointer'
                                            : null,
                                    }}
                                    onClick={() =>
                                        tableHeader.sortable
                                            ? props.orderByTableHeader(index)
                                            : null
                                    }
                                >
                                    <span>{tableHeader.title}</span>
                                    {tableHeader.sortable ? (
                                        <div
                                            className={
                                                tableHeader.sortable === 'desc'
                                                    ? styles.IconDESC
                                                    : styles.IconASC
                                            }
                                        >
                                            <ArrowDropDown />
                                        </div>
                                    ) : null}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map(({ data, actions }, currentIndex) => (
                        <tr
                            key={currentIndex}
                            onClick={actions?.onClick}
                            style={{
                                cursor: actions?.onClick ? 'pointer' : 'auto',
                            }}
                        >
                            {data.map((rowValue, rowIndex) => (
                                <td key={rowIndex}>{rowValue}</td>
                            ))}
                        </tr>
                    ))}

                    {!currentItems.length ? (
                        <tr>
                            <td
                                colSpan={props.data.headers.length}
                                style={{ height: '300px' }}
                            >
                                No data
                            </td>
                        </tr>
                    ) : null}
                </tbody>
            </table>

            <div className={styles.ContentTableMobile}>
                {currentItems.map(({ data, actions }, currentIndex) => (
                    <div
                        className={styles.TableBox}
                        key={currentIndex}
                        onClick={actions?.onClick}
                    >
                        {data.map((rowValue, rowIndex) => {
                            if (!rowIndex) {
                                return (
                                    <div
                                        className={styles.HeaderRow}
                                        key={rowIndex}
                                    >
                                        <div className={styles.HeaderRowHeader}>
                                            {
                                                props.data.headers[rowIndex]
                                                    ?.title
                                            }
                                        </div>
                                        <div
                                            className={styles.HeaderRowContent}
                                        >
                                            {rowValue}
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        className={styles.Content}
                                        key={rowIndex}
                                    >
                                        <span className={styles.Header}>
                                            {
                                                props.data.headers[rowIndex]
                                                    ?.title
                                            }
                                        </span>
                                        <span className={styles.Data}>
                                            {rowValue}
                                        </span>
                                    </div>
                                );
                            }
                        })}
                    </div>
                ))}
            </div>

            <div className={styles.pagination}>
                <div
                    className={styles.paginateButton}
                    onClick={handleBeginingPage}
                >
                    <ArrowFullLeft />
                </div>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={<ArrowRight />}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    previousLabel={<ArrowLeft />}
                    activeLinkClassName={styles.selected}
                    previousLinkClassName={styles.paginateButton}
                    nextLinkClassName={styles.paginateButton}
                    forcePage={selectedPage}
                    // forcePage={itemOffset}
                    // renderOnZeroPageCount={null}
                />
                <div className={styles.paginateButton} onClick={handleEndPage}>
                    <ArrowFullRight />
                </div>
            </div>
        </>
    );
}
