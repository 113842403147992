import { useMutation } from 'react-query';
import { useToastContext } from 'shared/components/Toast/context/ToastContext';
import { userService } from 'shared/services/UserService';
import { ICreateUserCredentials } from '../interfaces/ICreateUser';

export const useAddUser = () => {
    const { showToast } = useToastContext();

    const { mutateAsync, isError, isLoading } = useMutation(
        (data: ICreateUserCredentials) => userService.createUser(data),
        {
            onSuccess: () => {
                // console.log('USER ADDED', data);
                // push(ROUTES.ADMIN_DASHBOARD);
            },
            onError: (error: any) => {
                console.log('Error on login user - ', error?.detail);
                showToast('error', error?.detail);
            },
        }
    );

    return {
        mutateAsync,
        isLoading,
        error: {
            isError,
        },
    };
};
