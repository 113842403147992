import { AxiosRequestConfig, AxiosResponse } from "axios";
import { initializeApiInterceptors } from "./AxiosInterceptors";
import { createCancelTokenHandler, ICancelTokenHandler } from "./CreateCancelTokenHandler";
import { HttpClient } from "./HttpClient";

export type ResponseHandler<T = any, R = T> = (response: AxiosResponse<T>) => R;

function responseChecker<T>(response: AxiosResponse<T>) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    throw response.data;
}

class ApiService {
    private _httpClient = HttpClient.getInstance();
    private _cancelTokenHandler: ICancelTokenHandler;

    constructor() {
        initializeApiInterceptors(this._httpClient);
        this._cancelTokenHandler = createCancelTokenHandler();
    }

    responseHandler<T = any>({ data }: AxiosResponse<T>) {
        return data;
    }

    async get<T = any>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return responseChecker(
            await this._httpClient.get<T>(url, {
                ...config,
                cancelToken: this._cancelTokenHandler.handleRequestCancellation(url).token,
            })
        );
    }

    async post<T = any, B = any>(
        url: string,
        body: B,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return responseChecker(
            await this._httpClient.post<T>(url, body, {
                ...config,
                cancelToken: this._cancelTokenHandler.handleRequestCancellation(url).token,
            })
        );
    }

    async put<T = any, B = any>(
        url: string,
        body: B,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        return responseChecker(
            await this._httpClient.put<T>(url, body, {
                ...config,
                cancelToken: this._cancelTokenHandler.handleRequestCancellation(url).token,
            })
        );
    }

    async delete(
        url: string,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<null>> {
        return responseChecker(
            await this._httpClient.delete(url, {
                ...config,
                cancelToken: this._cancelTokenHandler.handleRequestCancellation(url).token,
            })
        );
    }
}

export const apiService = new ApiService();
