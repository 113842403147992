import { AxiosInstance, AxiosRequestConfig } from 'axios';
// import { LocalStorageKeysEnum } from 'shared/enums/LocalStorageKeysEnum';
// import {
//     clearLocalStorage,
//     setItemToLocalStorage,
// } from 'shared/utils/localStorageHandler';
// import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { userService } from './UserService';

export function initializeApiInterceptors(httpClient: AxiosInstance): void {
    interceptRequest(httpClient);
    interceptResponse(httpClient);
}

function appendToken(config: AxiosRequestConfig, token: string | null): void {
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
}

function interceptRequest(httpClient: AxiosInstance): void {
    httpClient.interceptors.request.use(
        (config) => {
            const userToken = userService.getToken();

            appendToken(config, userToken);

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}

function interceptResponse(httpClient: AxiosInstance): void {
    httpClient.interceptors.response.use(
        (response) => response,
        (error) => {
            if (!error.response) {
                if (error.message === 'Network Error') {
                    console.error('Network error - server is probably down');
                    throw error;
                } else {
                    console.error('Unknown error', error);
                    throw error;
                }
            } else {
                // const userRefreshToken = userService.getRefreshToken();

                switch (error.response.status) {
                    case 401: // Unauthorized
                        // if (!userRefreshToken) {
                        //     clearLocalStorage();
                        //     window.location.href = ROUTES.SIGN_IN;
                        // } else {
                        //     return userService
                        //         .refreshToken(userRefreshToken)
                        //         .then(({ token, refreshToken }: TUser) => {
                        //             setItemToLocalStorage(
                        //                 LocalStorageKeysEnum.token,
                        //                 { token, refreshToken }
                        //             );

                        //             return token;
                        //         })
                        //         .then((token: string) => {
                        //             appendToken(
                        //                 error.config,
                        //                 token,
                        //             );

                        //             return axios.request(error.config);
                        //         })
                        //         .catch(() => {
                        //             clearLocalStorage();
                        //             window.location.href = ROUTES.SIGN_IN;
                        //         });
                        // }
                        break;
                }
            }

            return error.response;
        }
    );
}
