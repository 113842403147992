import { createModel, IResponse } from './IResponse';

export interface IModalResponse<T = any> {
    total_pages: number;
    current_page: number;
    next_page: null;
    previous_page: null;
    items_per_page: number;
    total_items: number;
    order: any; // need to retype this
    items: Array<T>;
}

export const createModelFromResponse = <T>({
    total_pages,
    current_page,
    next_page,
    previous_page,
    items_per_page,
    total_items,
    ...props
}: IModalResponse<T>): IResponse<T> => {
    return createModel({
        totalPages: total_pages ?? 0,
        currentPage: current_page ?? 0,
        nextPage: next_page ?? null,
        previousPage: previous_page ?? null,
        itemsPerPage: items_per_page ?? 0,
        totalItems: total_items ?? 0,
        ...props,
    });
};
