import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { IEntity } from '../IEntity';
import { createUser, IUser } from './IUser';

export interface IUserResponse extends IEntity<number> {
    first_name: string;
    last_name: string;
    full_name: string;
    email: string;
    emailVerified: boolean;
    active: boolean;
    roles: Array<UserRoleEnum>;
    tokens: string[];
    npi_number: number;
    phone_number: string;
    redirect_phone_number: string;
    shift_start: number;
    shift_end: number;
    facilities?: FacOrg[];
    organization?: FacOrg;
}

export interface FacOrg extends IEntity<number | string> {
    name?: string;
}

export const createUserFromResponse = ({
    id,
    first_name,
    last_name,
    full_name,
    npi_number,
    phone_number,
    redirect_phone_number,
    shift_start,
    shift_end,
    roles,
    organization,
    facilities,
    ...props
}: IUserResponse): IUser => {
    return createUser({
        id: id.toString(),
        firstName: first_name ?? '',
        lastName: last_name ?? '',
        fullName: full_name ?? '',
        npiNumber: npi_number ?? 0,
        phoneNumber: phone_number ?? '',
        redirectPhoneNumber: redirect_phone_number ?? '',
        shiftStart: shift_start ?? 0,
        shiftEnd: shift_end ?? 0,
        role: roles[0],
        organization: organization ?? { id: '' },
        facilities: facilities.length ? facilities : [{ id: '' }],
        ...props,
    });
};
