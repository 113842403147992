import React from 'react';
import { cssClassNameFormatter } from 'shared/utils/cssClassNameFormatter';
import Loading from '../Loading/Loading';
import styles from './styles.module.scss';

export default function Button({
    onClick,
    title,
    className = '',
    type = 'button',
    fill = false,
    isDelete = false,
    loading,
    disabled,
}: {
    onClick?: () => any;
    title: string;
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    fill?: boolean;
    isDelete?: boolean;
    loading?: boolean;
    disabled?: boolean;
}) {
    return (
        <button
            className={cssClassNameFormatter([
                fill ? styles.button : styles.buttonoutline,
                isDelete
                    ? fill
                        ? styles.deleteButtonFill
                        : styles.deleteButtonOutline
                    : null,
                className,
            ])}
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            {loading ? <Loading height={20} color="#fff" /> : title}
        </button>
    );
}
