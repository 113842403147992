// import { PrivateRoute, PublicRoute } from 'shared/components/Routes';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { LayoutPage } from 'modules/layout/pages/LayoutPage';

import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.scss';
import Login from 'modules/user/pages/Login/Login';
import { PrivateRoute, PublicRoute } from 'shared/components/Routes';
import ForgotPassword from 'modules/user/pages/ForgotPassword/ForgotPassword';
import SetPassword from 'modules/user/pages/SetPassword/SetPassword';
import Toast from 'shared/components/Toast/Toast';
import { useEffect } from 'react';

const App = () => {
    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'dev') {
            document.title = '[DEV] Advanced Reporting';
        }

        if (process.env.REACT_APP_ENV === 'staging') {
            document.title = '[STAGE] Advanced Reporting';
        }
    }, []);

    return (
        <>
            <Router>
                <Switch>
                    {/* <Route
                    exact
                    strict
                    path={ROUTES.NOT_FOUND}
                    component={PageNotFound}
                /> */}

                    <PublicRoute
                        exact
                        strict
                        path={ROUTES.LOGIN}
                        component={Login}
                    />
                    <PublicRoute
                        exact
                        strict
                        path={ROUTES.LOGIN_FORGOT_PASSWORD}
                        component={ForgotPassword}
                    />
                    <PublicRoute
                        exact
                        strict
                        path={ROUTES.LOGIN_SET_PASSWORD}
                        component={SetPassword}
                    />

                    <PrivateRoute path={ROUTES.LAYOUT} component={LayoutPage} />
                </Switch>
            </Router>
            <Toast />
        </>
    );
};

export default App;
