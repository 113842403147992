import { ApiRoutesEnum } from 'shared/enums/ApiRoutesEnum';
import { IFacilities } from 'shared/interfaces/filters/IFacilities';
import { IMSDRGCode } from 'shared/interfaces/filters/IMSDRGCode';
import { IOrganizations } from 'shared/interfaces/filters/IOrganizations';
import {
    createModelFromResponse,
    IModalResponse,
} from 'shared/interfaces/response/IModalResponse';
import { IResponse } from 'shared/interfaces/response/IResponse';

import { apiService } from './ApiService';

class FiltersService {
    async getOrganizations(): Promise<IResponse<IOrganizations>> {
        const userResponse = apiService.responseHandler(
            await apiService.get<IModalResponse<IOrganizations>>(
                `${ApiRoutesEnum.ORGANIZATIONS}`,
                {
                    params: {
                        page: 1,
                        'order[name]': 'desc',
                    },
                }
            )
        );

        return createModelFromResponse(userResponse);
    }

    async getFacilities(
        organizationID?: number
    ): Promise<IResponse<IFacilities>> {
        const userResponse = apiService.responseHandler(
            await apiService.get<IModalResponse<IFacilities>>(
                `${ApiRoutesEnum.FACILITIES}`,
                {
                    params: {
                        organization: organizationID,
                    },
                }
            )
        );

        return createModelFromResponse(userResponse);
    }

    async getMSDRGCode(): Promise<IResponse<IMSDRGCode>> {
        const userResponse = apiService.responseHandler(
            await apiService.get<IModalResponse<IMSDRGCode>>(
                `${ApiRoutesEnum.MS_DRG_CODE}?`
            )
        );

        const userResponseModified = {
            total_pages: 0,
            current_page: 1,
            next_page: null as any,
            previous_page: null as any,
            items_per_page: 10,
            total_items: 0,
            order: {},
            items: userResponse as unknown as Array<IMSDRGCode>
        }

        return createModelFromResponse(userResponseModified);
    }
}

export const filtersService = new FiltersService();
