import { useQuery, useQueryClient } from 'react-query';
import { ITableHeaders } from 'shared/components/Table/Table';
import { userService } from 'shared/services/UserService';

interface IUseUsersList {
    headers: ITableHeaders[];
}

export const useUsersList = (params: IUseUsersList) => {
    const queryClient = useQueryClient();

    const { data, isLoading, refetch } = useQuery(
        ['User-list'],
        () => {
            let queryParams: any = {
                'order[id]=asc': 'desc',
            };

            params.headers.forEach((header) => {
                if (header.selected) {
                    switch (header.title) {
                        case 'User ID':
                            queryParams = {
                                'order[id]': header.sortable,
                            };
                            break;
                        case 'First name':
                            queryParams = {
                                'order[first_name]': header.sortable,
                            };
                            break;
                        case 'Last name':
                            queryParams = {
                                'order[last_name]': header.sortable,
                            };
                            break;
                        case 'Email':
                            queryParams = {
                                'order[email]': header.sortable,
                            };
                            break;
                        case 'Role':
                            queryParams = {
                                'order[role]': header.sortable,
                            };
                            break;

                        default:
                            break;
                    }
                }
            });

            return userService.getUsersList(queryParams);
        },
        {
            onError: (error: Error) => {
                console.log('Error on getting student - ', error);
            },
            initialData: () => queryClient.getQueryData(['User-list']),
            // staleTime: TimeDurationEnum.HOUR,
            // cacheTime: 0,}
        }
    );

    return {
        data,
        isLoading,
        refetch,
    };
};
