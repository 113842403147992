import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { IRouteLocationState } from 'shared/interfaces/IRouteLocationState';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { userService } from 'shared/services/UserService';

export const PrivateRoute: FC<RouteProps> = ({
    component: Component,
    children,
    ...rest
}) => {
    const userToken = userService.getToken();

    return (
        <Route
            {...rest}
            render={(routeProps) => {
                const locationState: IRouteLocationState = {
                    from: {
                        pathname: routeProps.location.pathname,
                    },
                    info: null,
                };

                return userToken ? (
                    children ?? <Component {...routeProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: ROUTES.LOGIN,
                            state: locationState,
                        }}
                    />
                );
            }}
        />
    );
};
