import { useFormik } from 'formik';
import * as Yup from 'yup';

import React from 'react';
import Button from 'shared/components/Button/Button';
import { FormInput } from 'shared/components/FormInputs/FormInputs';

import logo from '../../../../assets/images/zilius-full.png';
import styles from '../Login/styles.module.scss';
import { useResetPassword } from 'modules/user/hooks/useResetPassword';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { useHistory, useLocation } from 'react-router-dom';
import { useMagicLink } from 'modules/user/hooks/useMagicLink';
import Loading from 'shared/components/Loading/Loading';

function useQuery() {
    const { search } = useLocation();
    console.log(search);

    console.log(new URLSearchParams(window.location.search).get('email'));

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SetPassword() {
    const { push } = useHistory();

    const query = useQuery();
    const { resetPassword, isLoading } = useResetPassword();
    const { handleSubmit, handleChange, errors, touched, values } = useFormik({
        initialValues: {
            password: '',
            passwordConfirmation: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required('Password is required'),
            passwordConfirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm you password'),
        }),
        onSubmit: async (values) => {
            if (await resetPassword({ password: values.password })) {
                push(ROUTES.LAYOUT);
            }
        },
    });

    const { isLoading: isLoadingMagicLink } = useMagicLink(
        query.get('email'),
        query.get('hash')
    );

    return (
        <div className={styles.LoginScreen}>
            <form onSubmit={handleSubmit}>
                <img src={logo} alt="Zilius-logo-image" />
                {/* <span>
            Please enter your email address so we can send you an email
            to reset your password.
        </span> */}
                {isLoadingMagicLink ? (
                    <Loading height={200} />
                ) : (
                    <>
                        <FormInput
                            id="password"
                            label="Password"
                            type="password"
                            required
                            handleChange={handleChange}
                            value={values.password}
                            hasError={touched.password && errors.password}
                            errorMsg={errors.password}
                        />

                        <FormInput
                            id="passwordConfirmation"
                            label="Confirm password"
                            type="password"
                            required
                            handleChange={handleChange}
                            value={values.passwordConfirmation}
                            hasError={
                                touched.passwordConfirmation &&
                                errors.passwordConfirmation
                            }
                            errorMsg={errors.passwordConfirmation}
                        />

                        <div className={styles.FormButtons}>
                            <Button
                                title="Set password"
                                type="submit"
                                fill
                                className={styles.SubmitButton}
                                loading={isLoading}
                            />

                            {/* <Button title="Cancel" /> */}
                        </div>
                    </>
                )}
            </form>
        </div>
    );
}
