import { BoxInterface } from 'modules/homeScreen/interfaces/BoxInterface';
import React from 'react';

import styles from '../../pages/styles.module.scss';

export default function Box({ Icon, text, onClick }: BoxInterface) {
    return (
        <div className={styles.homeBox} onClick={onClick}>
            <div className={styles.Top}>
                <Icon />
            </div>
            <div className={styles.Bottom}>
                <span>{text}</span>
            </div>
        </div>
    );
}
