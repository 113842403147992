import { useUser } from 'modules/layout/hooks/useUser';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'shared/components/Button/Button';
import ContentTitle from 'shared/components/ContentTitle/ContentTitle';
import { ITableHeaders } from 'shared/components/Table/Table';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import UsersTable from '../components/UsersTable/UsersTable';
import styles from './styles.module.scss';

export default function UserManagement() {
    const { push } = useHistory();

    const [headers, setHeaders] = useState<ITableHeaders[]>([]);

    const { user } = useUser();

    useEffect(() => {
        switch (user.role) {
            case UserRoleEnum.ROLE_ZILIUS_ADMIN:
                setHeaders([
                    { title: 'User ID', sortable: 'asc' },
                    { title: 'First name', sortable: 'asc' },
                    { title: 'Last name', sortable: 'asc' },
                    { title: 'Email' },
                    { title: 'Role' },
                ]);

                break;

            default:
                break;
        }
    }, []);

    const orderByTableHeader = (index: number) => {
        let headerCopy = [...headers];

        // set headers to default
        headerCopy = headerCopy.map((header) => ({
            ...header,
            selected: false,
        }));

        // toggle selected header
        headerCopy[index] = {
            ...headerCopy[index],
            selected: true,
            sortable: headerCopy[index].sortable === 'asc' ? 'desc' : 'asc',
        };

        setHeaders(headerCopy);
    };

    return (
        <div>
            <div className={styles.ContentTitle}>
                <ContentTitle title="User management" />
                <div className={styles.ButtonsPlace}>
                    <Button
                        title="Add new user"
                        onClick={() => push(ROUTES.ADMIN_DASHBOARD_NEW)}
                        fill
                    />
                </div>
            </div>

            <UsersTable
                headers={headers}
                orderByTableHeader={orderByTableHeader}
            />
        </div>
    );
}
