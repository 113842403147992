import { TimeDurationEnum } from 'shared/enums/TimeDurationEnum';
import { IUser } from 'shared/interfaces/user/IUser';
import { userService } from 'shared/services/UserService';
import { useQuery, useQueryClient } from 'react-query';

export const useUser = () => {
    const queryClient = useQueryClient();
    const {
        data: user,
        isLoading,
        error,
    } = useQuery<IUser>('user', () => userService.me(), {
        initialData: () => queryClient.getQueryData('user'),
        staleTime: TimeDurationEnum.HALF_DAY,
        enabled: userService.getToken() !== null,
    });

    return {
        user,
        isLoading,
        serverErrorMessage: error,
    };
};
