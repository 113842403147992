import { useQuery, useQueryClient } from 'react-query';
import { ITableHeaders } from 'shared/components/Table/Table';
import { reportService } from 'shared/services/ReportsService';

interface IUseStandardReport {
    year: number;
    month: string | number;
    organization: number;
    facility: number;
    physician: number;
    code: number;
    headers: ITableHeaders[];
}

export const useStandardReport = (params: IUseStandardReport) => {
    const queryClient = useQueryClient();

    const { data, isLoading, isError, refetch } = useQuery(
        [
            'standard-report',
            params.code,
            params.facility,
            params.organization,
            params.year,
            params.month,
            params.physician,
            `${params.headers.filter((header) => header.selected)[0]?.title}=${
                params.headers.filter((header) => header.selected)[0]?.sortable
            }`,
        ],
        () => {
            let queryParams: any = {};

            params.headers.forEach((header) => {
                if (header.selected) {
                    switch (header.title) {
                        case 'Year':
                            queryParams = {
                                'order[year]': header.sortable,
                            };
                            break;
                        case 'Month':
                            queryParams = {
                                'order[month]': header.sortable,
                            };
                            break;
                        case 'Hospital System':
                            queryParams = {
                                'order[procedure.facility.organization.name]':
                                    header.sortable,
                            };
                            break;
                        case 'Hospital Site':
                            queryParams = {
                                'order[procedure.facility.name]':
                                    header.sortable,
                            };
                            break;
                        case 'Physician':
                            queryParams = {
                                'order[user.full_name]': header.sortable,
                            };
                            break;
                        case 'MS DRG Code':
                            queryParams = {
                                'order[ms_diagnostic_related_group.code]':
                                    header.sortable,
                            };
                            break;

                        default:
                            break;
                    }
                }
            });

            if (params?.year) {
                queryParams['year'] = params.year;
            }
            if (params?.month) {
                queryParams['month'] = params.month;
            }
            if (params?.organization) {
                queryParams['procedure.facility.organization.id'] =
                    params.organization;
            }
            if (params?.facility) {
                queryParams['procedure.facility.id'] = params.facility;
            }
            if (params?.physician) {
                queryParams['user.id'] = params.physician;
            }
            if (params?.code) {
                queryParams['procedure.msDiagnosticRelatedGroup.id'] =
                    params.code;
            }

            return reportService.getStandardReport(queryParams);
        },
        {
            onError: (error: Error) => {
                console.log('Error on getting student - ', error);
            },
            initialData: () =>
                queryClient.getQueryData([
                    'standard-report',
                    params.code,
                    params.facility,
                    params.organization,
                    params.year,
                    params.month,
                    params.physician,
                    `${
                        params.headers.filter((header) => header.selected)[0]
                            ?.title
                    }=${
                        params.headers.filter((header) => header.selected)[0]
                            ?.sortable
                    }`,
                ]),
            // staleTime: TimeDurationEnum.HOUR,
            // cacheTime: 0,
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError,
        refetch,
    };
};
