/**
 * Duration in milliseconds
 */
export enum TimeDurationEnum {
    DAY = 8.64e7,
    HALF_DAY = 4.32e7,
    HOUR = 3.6e6,
    MINUTE = 60000,
    SECOND = 1000,
}
