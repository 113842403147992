import React from 'react';
import ReactLoading from 'react-loading';

import style from './styles.module.scss';

interface ILoading {
    height: number;
    color?: string;
}

export default function Loading({ height, color = '#337AB7' }: ILoading) {
    return (
        <div className={style.Loading} style={{ height: `${height}px` }}>
            <ReactLoading
                type={'bubbles'}
                color={color}
                height={'35px'}
                width={'35px'}
            />
        </div>
    );
}
