import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { INavigationItem } from '../interfaces/navigation-item.interface';
import HomeScreen from 'modules/homeScreen/pages/HomeScreen';
import StandardReport from 'modules/standardReport/pages/StandardReport';
import SigmaReport from 'modules/sigmaReport/pages/SigmaReport';
import Procedures from 'modules/procedures/pages/Procedures';
import UserManagement from 'modules/UserManagement/pages/UserManagement';
import AddNewUser from 'modules/UserManagement/pages/AddNewUser/AddNewUser';
import NewProcedure from 'modules/procedures/components/newProcedure/NewProcedure';
import ProcedureDetails from 'modules/procedures/components/ProcedureDetails/ProcedureDetails';

export const NavigationItems: INavigationItem[] = [
    {
        path: ROUTES.HOME,
        exactPath: true,
        translateKey: 'Home',
        Component: HomeScreen,
        showInMenu: true,
    },
    {
        path: ROUTES.PROCEDURES,
        exactPath: true,
        translateKey: 'Procedures',
        Component: Procedures,
        showInMenu: true,
    },
    {
        path: ROUTES.PROCEDURES_NEW,
        exactPath: true,
        translateKey: 'Procedures',
        Component: NewProcedure,
        showInMenu: false,
    },
    {
        path: ROUTES.PROCEDURES_EDIT,
        exactPath: true,
        translateKey: 'Procedures',
        Component: NewProcedure,
        showInMenu: false,
    },
    {
        path: ROUTES.PROCEDURES_DETAILS,
        exactPath: true,
        translateKey: 'Procedures',
        Component: ProcedureDetails,
        showInMenu: false,
    },
    {
        path: ROUTES.STANDARD_REPORT,
        exactPath: true,
        translateKey: 'Standard Report',
        Component: StandardReport,
        showInMenu: true,
    },
    {
        path: ROUTES.SIGMA_REPORT,
        exactPath: true,
        translateKey: 'Sigma report',
        Component: SigmaReport,
        showInMenu: true,
    },
    {
        path: ROUTES.ADMIN_DASHBOARD,
        exactPath: true,
        translateKey: 'User management',
        Component: UserManagement,
        showInMenu: false,
    },
    {
        path: ROUTES.ADMIN_DASHBOARD_NEW,
        exactPath: true,
        translateKey: 'User management new',
        Component: AddNewUser,
        showInMenu: false,
    },
    {
        path: ROUTES.ADMIN_DASHBOARD_EDIT,
        exactPath: true,
        translateKey: 'User management edit',
        Component: AddNewUser,
        showInMenu: false,
    },
];
