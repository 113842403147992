export enum ROUTES {
    NOT_FOUND = '/404',
    LAYOUT = '/',
    HOME = '/dashboard',
    PROCEDURES = '/procedures',
    PROCEDURES_DETAILS = '/procedures/details/:procedureID',
    PROCEDURES_NEW = '/procedures/new',
    PROCEDURES_EDIT = '/procedures/new/:procedureID',
    STANDARD_REPORT = '/standard-report',
    SIGMA_REPORT = '/sigma-report',

    LOGIN = '/login',
    LOGIN_FORGOT_PASSWORD = '/login/forgot-password',
    LOGIN_SET_PASSWORD = '/login/set-password',
    ADMIN_DASHBOARD = '/admin/dashboard',
    ADMIN_DASHBOARD_NEW = '/admin/dashboard/newuser',
    ADMIN_DASHBOARD_EDIT = '/admin/dashboard/newuser/:userID',
}
