import { useUser } from 'modules/layout/hooks/useUser';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { useUsersList } from 'modules/UserManagement/hooks/useUsersList';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from 'shared/components/Loading/Loading';
import Table, { ITableHeaders } from 'shared/components/Table/Table';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { pathBuilder } from 'shared/utils/pathBuilder';

interface IUsersTable {
    headers: ITableHeaders[];
    orderByTableHeader?: (index: number) => void;
}

export default function UsersTable({
    headers,
    orderByTableHeader,
}: IUsersTable) {
    const { push } = useHistory();

    const [tableData, setTableData] = useState([]);

    const { data: UsersList, isLoading, refetch } = useUsersList({ headers });
    const { user } = useUser();

    useEffect(() => {
        // refetch procedure with sorting order from table headers
        // happens only when user click it
        if (tableData.length) {
            headers.forEach((header) => {
                if (header.selected) {
                    refetch(); // refetch when header change
                }
            });
        }
    }, [headers]);

    useEffect(() => {
        // console.log('effect', ProceduresData);
        const tempTableData: any = [];
        switch (user.role) {
            case UserRoleEnum.ROLE_ZILIUS_ADMIN:
                UsersList?.forEach((user) => {
                    // console.log('[PROCEDURE]: ', procedure);
                    tempTableData.push({
                        data: [
                            user.id.toString(),
                            user.first_name,
                            user.last_name,
                            user.email,
                            user.roles[0] === UserRoleEnum.ROLE_ZILIUS_ADMIN
                                ? 'ZILIUS ADMIN'
                                : user.roles[0] ===
                                  UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER
                                ? 'ORGANIZATION QUALITY OFFICER'
                                : user.roles[0] ===
                                  UserRoleEnum.ROLE_CARE_OPERATOR
                                ? 'CARE OPERATOR'
                                : 'REGULAR',
                        ],
                        actions: {
                            onClick: () => rowClickHandler(user.id.toString()),
                        },
                    });
                });

                setTableData(tempTableData);
                break;

            default:
                break;
        }
    }, [UsersList]);

    const rowClickHandler = (id: string) => {
        push(pathBuilder(ROUTES.ADMIN_DASHBOARD_EDIT, ':userID', id));
    };

    return (
        <div>
            {isLoading ? (
                <Loading height={300} />
            ) : (
                <Table
                    data={{
                        headers,
                        row: tableData,
                    }}
                    orderByTableHeader={orderByTableHeader}
                />
            )}
        </div>
    );
}
