import { useState } from 'react';

interface iUseModal {
    isOpen: boolean;
    isClose: boolean;
    openModal: () => void;
    closeModal: () => void;
}

const useModal = (): iUseModal => {
    const [isOpen, onOpenModal] = useState(false);
    const [isClose, onCloseModal] = useState(false);

    const openModal = () => {
        onOpenModal(true);
    };

    const closeModal = () => {
        onCloseModal(true);
        onOpenModal(false);
    };

    return { isOpen, isClose, openModal, closeModal };
};

export default useModal;
