import React from 'react';
import styles from './styles.module.scss';
import * as Yup from 'yup';

import { FormInput } from 'shared/components/FormInputs/FormInputs';

import logo from '../../../../assets/images/zilius-full.png';
import Button from 'shared/components/Button/Button';
import { useFormik } from 'formik';
import { useLogin } from 'modules/user/hooks/useLogin';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';

export default function Login() {
    const { push } = useHistory();

    const { login, isLoading } = useLogin();

    const { handleSubmit, handleChange, values, errors, touched } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email format')
                .required('Email is required'),
            password: Yup.string().required('Password is required'),
        }),
        onSubmit: async (values) => {
            await login({ email: values.email, password: values.password });
        },
    });

    return (
        <div className={styles.LoginScreen}>
            <form onSubmit={handleSubmit}>
                <img src={logo} alt="Zilius-logo-image" />
                <FormInput
                    id="email"
                    label="Email"
                    required
                    handleChange={handleChange}
                    value={values.email}
                    hasError={touched.email && errors.email}
                    errorMsg={errors.email}
                />
                <FormInput
                    id="password"
                    label="Password"
                    type="password"
                    required
                    handleChange={handleChange}
                    value={values.password}
                    hasError={touched.password && errors.password}
                    errorMsg={errors.password}
                />

                <div className={styles.FormButtons}>
                    <Button
                        title="Sign in"
                        type="submit"
                        fill
                        className={styles.SubmitButton}
                        loading={isLoading}
                    />
                    <Button
                        title="Forgot password"
                        type="button"
                        className={styles.ForgotButton}
                        onClick={() => push(ROUTES.LOGIN_FORGOT_PASSWORD)}
                    />

                    {/* <Button title="Cancel" /> */}
                </div>
            </form>
        </div>
    );
}
