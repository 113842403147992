import { queryClient } from 'index';
import { IMagicLinkResponse } from 'modules/user/interfaces/IMagicLinkResponse';
import {
    ICreateNewPasswordCredentials,
    IResetPasswordCredentials,
    ISignInCredentials,
} from 'modules/user/interfaces/ISignInCredentials';
import { IAssignUser } from 'modules/UserManagement/interfaces/IAssignUser';
import { ICreateUserCredentials } from 'modules/UserManagement/interfaces/ICreateUser';
import { ApiRoutesEnum } from 'shared/enums/ApiRoutesEnum';
import { LocalStorageKeysEnum } from 'shared/enums/LocalStorageKeysEnum';
import { ILoginResponse } from 'shared/interfaces/ILoginResponse';
import { IToken } from 'shared/interfaces/token/IToken';
import { IUser, TUser } from 'shared/interfaces/user/IUser';
import {
    createUserFromResponse,
    IUserResponse,
} from 'shared/interfaces/user/IUserResponse';
import { IUsersList } from 'shared/interfaces/user/IUsersList';

import {
    clearLocalStorage,
    getItemFromLocalStorage,
} from 'shared/utils/localStorageHandler';
import { apiService } from './ApiService';

class UserService {
    async me(): Promise<IUser> {
        const userResponse = apiService.responseHandler(
            await apiService.get<IUserResponse>(ApiRoutesEnum.ME)
        );

        return createUserFromResponse(userResponse);
    }

    async getUsersList(queryParams: any): Promise<IUsersList[]> {
        const userResponse = apiService.responseHandler(
            await apiService.get<IUsersList[]>(`${ApiRoutesEnum.USERS}`, {
                params: {
                    pagination: false,
                    physicians: false,
                    ...queryParams,
                },
            })
        );

        return userResponse;
    }

    async getUser(userID: number): Promise<IUser> {
        const userResponse = apiService.responseHandler(
            await apiService.get<IUserResponse>(
                `${ApiRoutesEnum.USERS}/${userID}`
            )
        );

        return createUserFromResponse(userResponse);
    }

    async getPhysiciansList(): Promise<IUsersList[]> {
        const userResponse = apiService.responseHandler(
            await apiService.get<IUsersList[]>(`${ApiRoutesEnum.USERS}`, {
                params: {
                    physicians: true,
                    pagination: false,
                },
            })
        );

        return userResponse;
    }

    async login(credentials: ISignInCredentials): Promise<TUser> {
        const { user, token_key, refresh_token_key } =
            apiService.responseHandler(
                await apiService.post<ILoginResponse, ISignInCredentials>(
                    ApiRoutesEnum.TOKEN,
                    credentials
                )
            );

        return {
            token: token_key,
            refreshToken: refresh_token_key,
            user: createUserFromResponse(user),
        };
    }

    async requestPasswordReset(
        credentials: IResetPasswordCredentials
    ): Promise<IMagicLinkResponse> {
        return apiService.responseHandler(
            await apiService.post<
                IMagicLinkResponse,
                IResetPasswordCredentials
            >(ApiRoutesEnum.REQUEST_PASSWORD_RESET, {
                ...credentials,
            })
        );
    }

    async getMagicLink(email: string, hash: string): Promise<TUser> {
        const { token_key, refresh_token_key, user } =
            apiService.responseHandler(
                await apiService.get<ILoginResponse>(
                    `${ApiRoutesEnum.REQUEST_PASSWORD_RESET}/${email}/${hash}`
                )
            );

        return {
            token: token_key,
            refreshToken: refresh_token_key,
            user: createUserFromResponse(user),
        };
    }

    async resetPassword(
        userID: number,
        credentials: ICreateNewPasswordCredentials
    ): Promise<any> {
        return await apiService.put<any, ICreateNewPasswordCredentials>(
            `${ApiRoutesEnum.USERS}/${userID}`,
            credentials
        );
    }

    async createUser(credentials: ICreateUserCredentials): Promise<any> {
        return await apiService.post<any, ICreateUserCredentials>(
            ApiRoutesEnum.USERS,
            credentials
        );
    }

    async updateUser({
        userID,
        ...rest
    }: ICreateUserCredentials): Promise<any> {
        return await apiService.put<any, ICreateUserCredentials>(
            `${ApiRoutesEnum.USERS}/${userID}`,
            rest
        );
    }

    async assignUser(credentials: IAssignUser): Promise<any> {
        return await apiService.post<any, IAssignUser>(
            ApiRoutesEnum.EMPLOYEES,
            credentials
        );
    }

    async deleteUser(userID: number): Promise<any> {
        return await apiService.delete(`${ApiRoutesEnum.USERS}/${userID}`);
    }

    // async refreshToken(refreshToken: string): Promise<TUser> {
    //     const { token_key, refresh_token_key, user } =
    //         apiService.responseHandler(
    //             await apiService.post<ITokenResponse, any>(
    //                 pathBuilder(
    //                     ApiRoutesEnum.REFRESH_TOKEN,
    //                     '{refreshToken}',
    //                     refreshToken
    //                 ),
    //                 {}
    //             )
    //         );

    //     return {
    //         token: token_key,
    //         refreshToken: refresh_token_key,
    //         user: createUserFromResponse(user),
    //     };
    // }

    // async register(
    //     credentials: IRegisterCredentials,
    //     userType: UserTypeEnum = UserTypeEnum.INSTRUCTOR
    // ): Promise<IUser> {
    //     const userResponse = apiService.responseHandler(
    //         await apiService.post<IUserResponse, IRegisterCredentials>(
    //             ApiRoutesEnum.REGISTER,
    //             credentials,
    //             {
    //                 params: {
    //                     type: userType,
    //                 },
    //             }
    //         )
    //     );

    //     return createUserFromResponse(userResponse);
    // }

    logout(): void {
        queryClient.clear();
        clearLocalStorage();
    }

    getToken(): string | null {
        const queryToken: IToken = queryClient.getQueryData('token');
        const localStorageToken = getItemFromLocalStorage(
            LocalStorageKeysEnum.token
        );

        if (queryToken?.token) return queryToken.token;
        if (localStorageToken) return localStorageToken.token;

        return null;
    }

    getRefreshToken(): string | null {
        const queryToken: IToken = queryClient.getQueryData('token');
        const localStorageToken = getItemFromLocalStorage(
            LocalStorageKeysEnum.token
        );

        if (queryToken?.refreshToken) return queryToken.refreshToken;
        if (localStorageToken) return localStorageToken.refreshToken;

        return null;
    }
}

export const userService = new UserService();
