import {
    IAddProcedure,
    IAddProcedureResponse,
    IProcedure,
} from 'modules/procedures/interfaces/IProcedure';

import { ApiRoutesEnum } from 'shared/enums/ApiRoutesEnum';

import { apiService } from './ApiService';

class ProcedureService {
    async getProceduresCollection(queryParams: any): Promise<IProcedure[]> {
        const serviceResponse = apiService.responseHandler(
            await apiService.get<IProcedure[]>(
                `${ApiRoutesEnum.PROCEDURES}?pagination=false`,
                {
                    params: {
                        ...queryParams,
                    },
                }
            )
        );

        return serviceResponse;
    }

    async getProcedureCollection(procedureID: any): Promise<IProcedure> {
        const serviceResponse = apiService.responseHandler(
            await apiService.get<IProcedure>(
                `${ApiRoutesEnum.PROCEDURES}/${procedureID}`
            )
        );

        return serviceResponse;
    }

    async addProcedure(formObj: IAddProcedure): Promise<IAddProcedureResponse> {
        const serviceResponse = apiService.responseHandler(
            await apiService.post<IAddProcedureResponse, IAddProcedure>(
                ApiRoutesEnum.PROCEDURES,
                formObj
            )
        );

        return serviceResponse;
    }

    async updateProcedure(
        formObj: IAddProcedure
    ): Promise<IAddProcedureResponse> {
        const serviceResponse = apiService.responseHandler(
            await apiService.put<IAddProcedureResponse, IAddProcedure>(
                `${ApiRoutesEnum.PROCEDURES}/${formObj.id}`,
                formObj
            )
        );

        return serviceResponse;
    }

    async deleteProcedure(procedureID: number): Promise<any> {
        return await apiService.delete(
            `${ApiRoutesEnum.PROCEDURES}/${procedureID}`
        );
    }
}

export const procedureService = new ProcedureService();
