import { useUser } from 'modules/layout/hooks/useUser';
import { useQuery, useQueryClient } from 'react-query';
import { TimeDurationEnum } from 'shared/enums/TimeDurationEnum';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { filtersService } from 'shared/services/FiltersService';

export const useFacilities = (organizationID: number) => {
    const queryClient = useQueryClient();
    const { user } = useUser();
    const { data, isLoading, isError, refetch } = useQuery(
        ['facilities', organizationID],
        () => {
            if (user.role !== UserRoleEnum.ROLE_ZILIUS_ADMIN) {
                return filtersService.getFacilities();
            }
            return filtersService.getFacilities(organizationID);
        },
        {
            onError: (error: Error) => {
                console.log('Error on getting facilities - ', error);
            },
            initialData: () =>
                queryClient.getQueryData(['facilities', organizationID]),
            enabled:
                user.role === UserRoleEnum.ROLE_ZILIUS_ADMIN
                    ? Boolean(organizationID)
                    : true,
            staleTime: TimeDurationEnum.HALF_DAY,
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError,
        refetch,
    };
};
