import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { IEntity } from '../IEntity';
import { IToken } from '../token/IToken';
import { FacOrg } from './IUserResponse';

export type TUser = IToken & { user: IUser };

export interface IUser extends IEntity {
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    emailVerified: boolean;
    active: boolean;
    role: UserRoleEnum;
    tokens: string[];
    npiNumber: number;
    phoneNumber: string;
    redirectPhoneNumber: string;
    shiftStart: number;
    shiftEnd: number;
    facilities?: FacOrg[];
    organization?: FacOrg;
}

export const createUser = ({ id, ...props }: Partial<IUser>) => {
    if (!id) {
        throw new Error('Unable to create a user without an ID!');
    }

    return {
        id,
        ...props,
    } as IUser;
};
