import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useToastContext } from 'shared/components/Toast/context/ToastContext';
import { LocalStorageKeysEnum } from 'shared/enums/LocalStorageKeysEnum';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { TUser } from 'shared/interfaces/user/IUser';
import { userService } from 'shared/services/UserService';

export const useMagicLink = (email: string, hash: string) => {
    const [userToken, setUserToken] = useLocalStorage(
        LocalStorageKeysEnum.token,
        null
    );

    const queryClient = useQueryClient();
    const { push } = useHistory();
    const { showToast } = useToastContext();

    const { isLoading } = useQuery(
        ['user-magic-link'],
        () => userService.getMagicLink(email, hash),
        {
            enabled: Boolean(email) && Boolean(hash),
            onSuccess: (userData: TUser) => {
                const { token, refreshToken, user } = userData;

                setUserToken({ token, refreshToken });

                queryClient.setQueryData('token', userToken);
                queryClient.setQueryData('user', user);

                push(ROUTES.LOGIN_SET_PASSWORD);
            },
            onError: (error: any) => {
                showToast('error', error?.detail);
            },
        }
    );

    return {
        isLoading,
    };
};
