import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import Button from 'shared/components/Button/Button';
import ContentTitle from 'shared/components/ContentTitle/ContentTitle';
import { FormInput, FormSelect } from 'shared/components/FormInputs/FormInputs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { useAddUser } from 'modules/UserManagement/hooks/useAddUser';
import { ICreateUserCredentials } from 'modules/UserManagement/interfaces/ICreateUser';
import { useHistory, useParams } from 'react-router-dom';
import { useOrganizations } from 'shared/hooks/Filters/useOrganizations';
import { useFacilities } from 'shared/hooks/Filters/useFacilities';
import { useAssignUser } from 'modules/UserManagement/hooks/useAssignUser';
import { IAssignUser } from 'modules/UserManagement/interfaces/IAssignUser';
import { useGetUser } from 'modules/UserManagement/hooks/useGetUser';
import { useUpdateUser } from 'modules/UserManagement/hooks/useUpdateUser';
import Loading from 'shared/components/Loading/Loading';
import useModal from 'shared/hooks/useModal';
import Modal from 'shared/components/Modal/Modal';
import { useDeleteUser } from 'modules/UserManagement/hooks/useDeleteUser';

export default function AddNewUser() {
    const { userID }: any = useParams();
    const isAddMode = !userID;

    const { mutateAsync, isLoading } = useAddUser();
    const { mutateAsync: updateUser, isLoading: isUpdateUserLoading } =
        useUpdateUser();

    const { mutateAsync: assignUser, isLoading: isAssignLoading } =
        useAssignUser();

    const { data: UserDetails, isLoading: isUserDetailsLoading } =
        useGetUser(userID);

    const { mutateAsync: deleteUser, isLoading: isDeleteUserLoading } =
        useDeleteUser();

    const { goBack } = useHistory();

    const { isOpen, closeModal, openModal } = useModal();

    const { handleSubmit, handleChange, values, errors, touched, setValues } =
        useFormik({
            initialValues: {
                firstName: '',
                lastName: '',
                email: '',
                role: '',
                organization: '',
                facility: '',
            },
            validationSchema: Yup.object({
                firstName: Yup.string().required('First name is required'),
                lastName: Yup.string().required('Last name is required'),
                email: Yup.string()
                    // .email('Invalid email format')
                    .required('Email is required'),
                role: Yup.string().required('Role is required'),
                organization: Yup.string()
                    .notRequired()
                    .when('role', {
                        is: (value: string) =>
                            value === UserRoleEnum.ROLE_ZILIUS_ADMIN,
                        then: Yup.string(),
                        otherwise: Yup.string().required(
                            'Hospital System is required'
                        ),
                    }),
                facility: Yup.string()
                    .notRequired()
                    .when('role', {
                        is: (value: string) =>
                            value ===
                                UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER ||
                            value === UserRoleEnum.ROLE_ZILIUS_ADMIN,
                        then: Yup.string(),
                        otherwise: Yup.string().required(
                            'Hospital Site is requeired'
                        ),
                    }),
            }),
            onSubmit: async (values) => {
                const createUser: ICreateUserCredentials = {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    email: values.email,
                    roles: [values.role],
                };

                if (isAddMode) {
                    // add user block

                    const { data } = await mutateAsync(createUser);

                    if (values.role !== UserRoleEnum.ROLE_ZILIUS_ADMIN) {
                        const assignUserObj: IAssignUser = {
                            user: `/api/v1/users/${data?.id}`,
                            action: 'add',
                        };

                        if (values.organization) {
                            assignUserObj.organization = `/api/v1/organizations/${values.organization}`;
                        }

                        if (values.facility) {
                            assignUserObj.facility = `/api/v1/facilities/${values.facility}`;
                        }

                        await assignUser(assignUserObj);
                    } else {
                        goBack();
                    }
                } else {
                    // edit user block

                    if (
                        UserDetails.organization.id.toString() !==
                            values.organization ||
                        UserDetails.facilities[0].id.toString() !==
                            values.facility
                    ) {
                        // remove user from organization or facility
                        const reassignObj: IAssignUser = {
                            user: `/api/v1/users/${UserDetails.id}`,
                            action: 'remove',
                        };
                        console.log(
                            '[ASSIGN USER]',
                            UserDetails.organization.id
                        );

                        if (UserDetails.organization.id) {
                            reassignObj.organization = `/api/v1/organizations/${UserDetails.organization.id}`;
                        }

                        if (UserDetails.facilities[0].id) {
                            reassignObj.facility = `/api/v1/facilities/${UserDetails.facilities[0].id}`;
                        }

                        await assignUser(reassignObj);

                        const assignUserObj: IAssignUser = {
                            user: `/api/v1/users/${UserDetails.id}`,
                            action: 'add',
                        };

                        if (values.organization) {
                            assignUserObj.organization = `/api/v1/organizations/${values.organization}`;
                        }

                        if (values.facility) {
                            assignUserObj.facility = `/api/v1/facilities/${values.facility}`;
                        }

                        await assignUser(assignUserObj);
                    }

                    // update user

                    createUser.userID = +UserDetails.id;

                    await updateUser(createUser);
                }
            },
        });

    const { data: organizationList, isLoading: isOrganizationListLodaing } =
        useOrganizations();

    const { data: facilitiesList, isLoading: isFacilitiesListLodaing } =
        useFacilities(+values.organization);

    useEffect(() => {
        if (!isAddMode && UserDetails) {
            setValues({
                firstName: UserDetails.firstName,
                lastName: UserDetails.lastName,
                email: UserDetails.email,
                role: UserDetails.role,
                organization: UserDetails.organization.id.toString(),
                facility: UserDetails.facilities[0].id.toString(),
            });
        }
    }, [UserDetails]);

    return (
        <>
            <div>
                <ContentTitle
                    title={`${isAddMode ? 'Add new user' : 'Edit user'}`}
                />
                {isUserDetailsLoading ||
                isOrganizationListLodaing ||
                isFacilitiesListLodaing ? (
                    <Loading height={300} />
                ) : (
                    <form onSubmit={handleSubmit}>
                        <FormInput
                            id="firstName"
                            label="First name"
                            required
                            handleChange={handleChange}
                            value={values.firstName}
                            hasError={touched.firstName && errors.firstName}
                            errorMsg={errors.firstName}
                        />
                        <FormInput
                            id="lastName"
                            label="Last name"
                            required
                            handleChange={handleChange}
                            value={values.lastName}
                            hasError={touched.lastName && errors.lastName}
                            errorMsg={errors.lastName}
                        />
                        <FormInput
                            id="email"
                            label="Email"
                            required
                            handleChange={handleChange}
                            value={values.email}
                            hasError={touched.email && errors.email}
                            errorMsg={errors.email}
                        />

                        {/* <FormInput
                    id="password"
                    label="Passwrod"
                    required
                    handleChange={handleChange}
                    value={values.password}
                    hasError={touched.password && errors.password}
                    errorMsg={errors.password}
                /> */}

                        <FormSelect
                            id="role"
                            label="Role"
                            required
                            handleChange={handleChange}
                            value={values.role}
                            hasError={touched.role && errors.role}
                            errorMsg={errors.role}
                        >
                            <option value={''} disabled>
                                Select role
                            </option>
                            <option value={UserRoleEnum.ROLE_ZILIUS_ADMIN}>
                                ZILIUS ADMIN
                            </option>
                            <option
                                value={
                                    UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER
                                }
                            >
                                ORGANIZATION QUALITY OFFICER
                            </option>
                            <option value={UserRoleEnum.ROLE_CARE_OPERATOR}>
                                CARE OPERATOR
                            </option>
                        </FormSelect>

                        {values.role ===
                            UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER ||
                        values.role === UserRoleEnum.ROLE_CARE_OPERATOR ? (
                            <FormSelect
                                id="organization"
                                label="Hospital System"
                                required
                                handleChange={handleChange}
                                value={values.organization}
                                hasError={
                                    touched.organization && errors.organization
                                }
                                errorMsg={errors.organization}
                            >
                                <option value={''} disabled>
                                    Please select organization
                                </option>
                                {organizationList?.items.map((organization) => (
                                    <option
                                        key={organization.id}
                                        value={organization.id}
                                    >
                                        {organization.name}
                                    </option>
                                ))}
                            </FormSelect>
                        ) : null}

                        {values.role === UserRoleEnum.ROLE_CARE_OPERATOR ? (
                            <FormSelect
                                id="facility"
                                label="Hospital Site"
                                required
                                disabled={values.organization === ''}
                                handleChange={handleChange}
                                value={values.facility}
                                hasError={touched.facility && errors.facility}
                                errorMsg={errors.facility}
                            >
                                <option value={''} disabled>
                                    Select facility
                                </option>
                                {facilitiesList?.items.map((facility) => (
                                    <option
                                        key={facility.id}
                                        value={facility.id}
                                    >
                                        {facility.name}
                                    </option>
                                ))}
                            </FormSelect>
                        ) : null}

                        <div className={styles.FormButtons}>
                            <div>
                                <Button
                                    title="Submit"
                                    type="submit"
                                    fill
                                    loading={
                                        isLoading ||
                                        isAssignLoading ||
                                        isUpdateUserLoading
                                    }
                                />
                                <Button
                                    title="Cancel"
                                    onClick={() => goBack()}
                                />
                            </div>
                            {!isAddMode ? (
                                <Button
                                    title="Delete"
                                    onClick={openModal}
                                    isDelete
                                />
                            ) : null}
                        </div>
                    </form>
                )}
            </div>

            {isOpen ? (
                <Modal
                    title="Are you sure ?"
                    close={closeModal}
                    render={() => (
                        <div className={styles.ModalContent}>
                            <span>You will delete this user.</span>
                            <Button
                                title="Delete"
                                className={styles.Button}
                                isDelete
                                fill
                                onClick={async () => {
                                    if (await deleteUser(+UserDetails.id)) {
                                        closeModal();
                                        goBack();
                                    }
                                }}
                                loading={isDeleteUserLoading}
                            />
                        </div>
                    )}
                />
            ) : null}
        </>
    );
}
