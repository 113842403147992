import { useUser } from 'modules/layout/hooks/useUser';
import React, { useEffect, useState } from 'react';
import Button from 'shared/components/Button/Button';
import Loading from 'shared/components/Loading/Loading';
import { ITableHeaders } from 'shared/components/Table/Table';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { useFacilities } from 'shared/hooks/Filters/useFacilities';
import { useMSDRGCodes } from 'shared/hooks/Filters/useMSDRGCodes';
import { useOrganizations } from 'shared/hooks/Filters/useOrganizations';

import filterStyle from '../../../../styles/filter.style.module.scss';
import styles from './styles.module.scss';
import StandardReportTable from '../standardReportTable/StandardReportTable';
import { usePhysicians } from 'shared/hooks/Filters/usePhysicians';

export default function DataView() {
    const [searchParams, setSearchParams] = useState({
        year: 0,
        month: 0,
        organization: 0,
        facility: 0,
        physician: 0,
        code: 0,
    });
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [selectedOrganization, setSelectedOrganization] = useState(0);
    const [selectedFacility, setSelectedFacility] = useState(0);
    const [selectedPhysician, setSelectedPhysician] = useState(0);
    const [selectedCode, setSelectedCode] = useState(0);

    const { user } = useUser();

    const [headers, setHeaders] = useState<ITableHeaders[]>([]);

    const { data: organizationList, isLoading: isOrganizationListLoading } =
        useOrganizations();

    const { data: facilitiesList, isLoading: isFacilitiesListLoading } =
        useFacilities(selectedOrganization);

    const { data: MSDRGCodesList, isLoading: isMSDRGCodesListLoading } =
        useMSDRGCodes();

    const { data: physiciansList, isLoading: isPhysicianListLoading } =
        usePhysicians();

    useEffect(() => {
        switch (user.role) {
            case UserRoleEnum.ROLE_ZILIUS_ADMIN:
            case UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER:
            case UserRoleEnum.ROLE_CARE_OPERATOR:
                setHeaders([
                    { title: 'Year', sortable: 'asc' },
                    { title: 'Month', sortable: 'asc' },
                    { title: 'Hospital System', sortable: 'asc' },
                    { title: 'Hospital Site', sortable: 'asc' },
                    { title: 'Physician', sortable: 'asc' },
                    { title: 'Procedure' },
                    { title: 'MS DRG Code', sortable: 'asc' },
                    { title: 'Discharges' },
                    { title: 'Alerts' },
                    { title: 'Readmits' },
                    { title: 'Readmits avoided' },
                    { title: 'Readmits cost avoided' },
                    { title: 'Readmits cost' },
                    { title: 'Cumulative losses' },
                    { title: 'Baseline readmit %' },
                ]);

                break;

            default:
                break;
        }
    }, []);

    const orderByTableHeader = (index: number) => {
        let headerCopy = [...headers];

        // set headers to default
        headerCopy = headerCopy.map((header) => ({
            ...header,
            selected: false,
        }));

        // toggle selected header
        headerCopy[index] = {
            ...headerCopy[index],
            selected: true,
            sortable: headerCopy[index].sortable === 'asc' ? 'desc' : 'asc',
        };

        setHeaders(headerCopy);
    };

    return (
        <>
            <div className={filterStyle.searchFilterBox}>
                <div className={filterStyle.filters}>
                    <div className={filterStyle.filterInput}>
                        <span>Year</span>
                        <select
                            name=""
                            id=""
                            onChange={(e) => setYear(+e.target.value)}
                            value={year}
                        >
                            <option value={0}>All</option>
                            <option value="2022">2022</option>
                        </select>
                    </div>
                    <div className={filterStyle.filterInput}>
                        <span>Month</span>
                        <select
                            name=""
                            id=""
                            onChange={(e) => setMonth(+e.target.value)}
                            value={month}
                        >
                            <option value={0}>All</option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                        </select>
                    </div>
                    {user.role === UserRoleEnum.ROLE_ZILIUS_ADMIN ? (
                        <div className={filterStyle.filterInput}>
                            {isOrganizationListLoading ? (
                                <Loading height={60} />
                            ) : (
                                <>
                                    <span>Hospital System</span>
                                    <select
                                        name=""
                                        id=""
                                        onChange={(e) => {
                                            setSelectedOrganization(
                                                +e.target.value
                                            );
                                            setSelectedFacility(0);
                                        }}
                                        value={selectedOrganization}
                                    >
                                        <option value={0}>All</option>
                                        {organizationList?.items.map(
                                            (organization) => (
                                                <option
                                                    key={organization.id}
                                                    value={organization.id}
                                                >
                                                    {organization.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </>
                            )}
                        </div>
                    ) : null}
                    {user.role === UserRoleEnum.ROLE_ZILIUS_ADMIN ||
                    user.role ===
                        UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER ? (
                        <div className={filterStyle.filterInput}>
                            {isFacilitiesListLoading ? (
                                <Loading height={60} />
                            ) : (
                                <>
                                    <span>Hospital Site</span>
                                    <select
                                        name=""
                                        id=""
                                        onChange={(e) =>
                                            setSelectedFacility(+e.target.value)
                                        }
                                        value={selectedFacility}
                                        disabled={
                                            user.role ===
                                            UserRoleEnum.ROLE_ZILIUS_ADMIN
                                                ? selectedOrganization === 0
                                                : false
                                        }
                                    >
                                        <option value={0}>All</option>
                                        {facilitiesList?.items.map(
                                            (facility) => (
                                                <option
                                                    key={facility.id}
                                                    value={facility.id}
                                                >
                                                    {facility.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </>
                            )}
                        </div>
                    ) : null}
                    <div className={filterStyle.filterInput}>
                        {isPhysicianListLoading ? (
                            <Loading height={60} />
                        ) : (
                            <>
                                <span>Physician</span>
                                <select
                                    name=""
                                    id=""
                                    onChange={(e) =>
                                        setSelectedPhysician(+e.target.value)
                                    }
                                    value={selectedPhysician}
                                >
                                    <option value={0}>All</option>
                                    {physiciansList.map((physician) => (
                                        <option
                                            value={physician.id}
                                            key={physician.id}
                                        >
                                            {physician.full_name}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                    </div>
                    <div className={filterStyle.filterInput}>
                        {isMSDRGCodesListLoading ? (
                            <Loading height={60} />
                        ) : (
                            <>
                                <span>MS DRG Code</span>
                                <select
                                    name=""
                                    id=""
                                    onChange={(e) =>
                                        setSelectedCode(+e.target.value)
                                    }
                                    value={selectedCode}
                                >
                                    <option value={0}>All</option>
                                    {MSDRGCodesList?.items.map((code) => (
                                        <option key={code.id} value={code.id}>
                                            {code.code} - {code.description}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                    </div>
                </div>
                <div>
                    <Button
                        title="Clear"
                        onClick={() => {
                            setYear(0);
                            setMonth(0);
                            setSelectedOrganization(0);
                            setSelectedFacility(0);
                            setSelectedPhysician(0);
                            setSelectedCode(0);

                            setSearchParams({
                                year: 0,
                                month: 0,
                                organization: 0,
                                facility: 0,
                                physician: 0,
                                code: 0,
                            });
                        }}
                        className={styles.SeachButton}
                    />
                    <Button
                        title="Search"
                        onClick={() => {
                            setSearchParams({
                                year,
                                month,
                                organization: selectedOrganization,
                                facility: selectedFacility,
                                physician: selectedPhysician,
                                code: selectedCode,
                            });
                        }}
                        className={styles.SeachButton}
                        fill
                    />
                </div>
            </div>

            <StandardReportTable
                searchParams={searchParams}
                headers={headers}
                orderByTableHeader={orderByTableHeader}
            />
        </>
    );
}
