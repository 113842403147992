import { ApiRoutesEnum } from "shared/enums/ApiRoutesEnum";
import { ROUTES } from "modules/navigation/enums/RoutesEnum";

type Path = ApiRoutesEnum | ROUTES;

export function pathBuilder(
    path: Path,
    paramToReplace: string,
    paramValue: string
): string {
    const newPath = path.replace(paramToReplace, paramValue);

    return newPath;
}
