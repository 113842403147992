import { useQuery, useQueryClient } from 'react-query';
import { userService } from 'shared/services/UserService';

export const useGetUser = (userID: number) => {
    const queryClient = useQueryClient();

    const { data, isLoading, refetch } = useQuery(
        ['User', userID],
        () => {
            return userService.getUser(userID);
        },
        {
            onError: (error: Error) => {
                console.log('Error on getting user - ', error);
            },
            enabled: Boolean(userID),
            initialData: () => queryClient.getQueryData(['User', userID]),
            // staleTime: TimeDurationEnum.HOUR,
            // cacheTime: 0,}
        }
    );

    return {
        data,
        isLoading,
        refetch,
    };
};
