import { useUser } from 'modules/layout/hooks/useUser';
import { useStandardReportMonthly } from 'modules/standardReport/hooks/useStandardReportMonthly';
import React, { useEffect, useState } from 'react';
import ExportButton from 'shared/components/Button/ExportButton';
import Loading from 'shared/components/Loading/Loading';
import Table, { ITableHeaders } from 'shared/components/Table/Table';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { dollarConverter } from 'shared/utils/currencyFormater';

import styles from './styles.module.scss';

interface IStandardReportProps {
    searchParams: {
        organization: number;
        facility: number;
        code: number;
        year: number;
        month: string | number;
        physician: number;
    };
    headers: ITableHeaders[];
    orderByTableHeader?: (index: number) => void;
}

export default function StandardReportTableMonthly({
    searchParams,
    headers,
    orderByTableHeader,
}: IStandardReportProps) {
    const [tableData, setTableData] = useState([]);

    const { user } = useUser();

    const {
        data: StandardReportMonthlyData,
        isLoading,
        refetch,
    } = useStandardReportMonthly({
        ...searchParams,
        headers,
    });

    useEffect(() => {
        // refetch procedure with sorting order from table headers
        // happens only when user click it
        if (tableData.length) {
            headers.forEach((header) => {
                if (header.selected) {
                    refetch(); // refetch when header change
                }
            });
        }
    }, [headers]);

    useEffect(() => {
        // console.log('effect', ProceduresData);
        const headersTemplate: any = [
            ['Re-Admission Alerts'],
            ['Admitted'],
            ['Avoided Re-Admissions'],
            ['Re-Admit %'],
            ['Avoided Cost $'],
        ];
        const tempTableData: any = [];
        switch (user.role) {
            case UserRoleEnum.ROLE_ZILIUS_ADMIN:
            case UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER:
            case UserRoleEnum.ROLE_CARE_OPERATOR:
                headersTemplate.forEach((header: any, index: number) => {
                    StandardReportMonthlyData?.forEach((report) => {
                        switch (index) {
                            case 0:
                                header.push(report.alerts);
                                break;
                            case 1:
                                header.push(report.readmits);
                                break;
                            case 2:
                                header.push(report.readmits_avoided);
                                break;
                            case 3:
                                header.push(
                                    (report.readmit * 100).toFixed(2) + '%'
                                );
                                break;
                            case 4:
                                header.push(
                                    dollarConverter.format(
                                        report.readmits_cost_avoided
                                    )
                                );
                                break;
                            default:
                                break;
                        }
                    });
                });

                headersTemplate.forEach((row: any) => {
                    tempTableData.push({
                        data: row,
                    });
                });

                setTableData(tempTableData);
                break;

            default:
                break;
        }
    }, [StandardReportMonthlyData]);

    return (
        <>
            {isLoading ? (
                <Loading height={300} />
            ) : (
                <>
                    <div className={styles.ButtonsPlace}>
                        <ExportButton
                            title="Export"
                            exportData={tableData}
                            headers={headers}
                            fileName="Standard_Report_Monthly"
                            style={styles.Button}
                        />
                    </div>
                    <Table
                        data={{
                            headers,
                            row: tableData,
                        }}
                        orderByTableHeader={orderByTableHeader}
                    />
                </>
            )}
        </>
    );
}
