import { useQuery, useQueryClient } from 'react-query';
import { TimeDurationEnum } from 'shared/enums/TimeDurationEnum';
import { userService } from 'shared/services/UserService';

export const usePhysicians = () => {
    const queryClient = useQueryClient();
    const { data, isLoading, isError, refetch } = useQuery(
        ['physicians'],
        () => {
            return userService.getPhysiciansList();
        },
        {
            onError: (error: Error) => {
                console.log('Error on getting facilities - ', error);
            },
            initialData: () => queryClient.getQueryData(['facilities']),
            staleTime: TimeDurationEnum.HALF_DAY,
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError,
        refetch,
    };
};
