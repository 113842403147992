import React from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';

interface iFormInputs {
    children?: any;
    id: string;
    label: string;
    required?: boolean;
    type?: string;
    handleChange?: (e: React.ChangeEvent<any>) => void;
    value?: any;
    disabled?: boolean;
    hasError?: boolean | string;
    errorMsg?: string;
    options?: any
}

export function FormSelect({
    children,
    id,
    label,
    required,
    disabled = false,
    handleChange,
    value,
    hasError,
    errorMsg,
}: iFormInputs) {
    return (
        <div className={styles.procedureFormInput}>
            <div>
                <label htmlFor={id}>{label}</label>
                {required ? <span>*</span> : null}
            </div>
            <select
                name={id}
                id={id}
                disabled={disabled}
                onChange={handleChange}
                value={value}
            >
                {children}
            </select>
            {hasError ? <p>{errorMsg}</p> : null}
        </div>
    );
}

export function FormSelectWithSearch({
    options,
    name,
    label,
    required,
    disabled = false,
    handleChange,
    value,
    hasError,
    errorMsg,
    placeholder,
    styling
}: any) {
    return (
        <div className={styles.procedureFormInput}>
            <div>
                <label htmlFor={name}>{label}</label>
                {required ? <span>*</span> : null}
            </div>
            <Select 
                name={name}
                onChange={handleChange}
                value={value}
                options={options}
                placeholder={placeholder}
                isDisabled={disabled}
                styles={styling}
            />
            {hasError ? <p>{errorMsg}</p> : null}
        </div>
    );
}

export function FormInput({
    id,
    label,
    required,
    type = 'text',
    handleChange,
    value,
    disabled = false,
    hasError,
    errorMsg,
}: iFormInputs) {
    return (
        <div className={styles.procedureFormInput}>
            <div>
                <label htmlFor={id}>{label}</label>
                {required ? <span>*</span> : null}
            </div>
            <input
                name={id}
                id={id}
                type={type}
                onChange={handleChange}
                value={value}
                disabled={disabled}
            />
            {hasError ? <p>{errorMsg}</p> : null}
        </div>
    );
}

export function FormTextArea({ id, label, required }: iFormInputs) {
    return (
        <div className={styles.procedureFormInput}>
            <div>
                <label htmlFor={id}>{label}</label>
                {required ? <span>*</span> : null}
            </div>
            <textarea
                name=""
                id="msgdrgdescription"
                cols={20}
                rows={10}
            ></textarea>
        </div>
    );
}
