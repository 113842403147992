export interface IResponse<T = any> {
    totalPages: number;
    currentPage: number;
    nextPage: null;
    previousPage: null;
    itemsPerPage: number;
    totalItems: number;
    order: any;
    items: Array<T>;
}

export const createModel = <T>({ ...props }: Partial<IResponse<T>>) => {
    return {
        totalPages: 0,
        currentPage: 0,
        nextPage: null,
        previousPage: null,
        itemsPerPage: 0,
        totalItems: 0,
        order: {},
        items: [],
        ...props,
    } as IResponse<T>;
};
