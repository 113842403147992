import { ApiRoutesEnum } from 'shared/enums/ApiRoutesEnum';
import { ISigmaResponse } from 'shared/interfaces/reports/ISigmaReponse';
import { IStandardMonthlyResponse } from 'shared/interfaces/reports/IStandardMonthlyResponse';
import { IStandardResponse } from 'shared/interfaces/reports/IStandardResponse';

import { apiService } from './ApiService';

class ReportService {
    async getStandardReport(queryParams: any): Promise<IStandardResponse[]> {
        const userResponse = apiService.responseHandler(
            await apiService.get<IStandardResponse[]>(
                `${ApiRoutesEnum.STANDARD_REPORT}?pagination=false`,
                {
                    params: { ...queryParams },
                }
            )
        );

        return userResponse;
    }

    async getStandardReportMonthly(
        queryParams: any
    ): Promise<IStandardMonthlyResponse[]> {
        const userResponse = apiService.responseHandler(
            await apiService.get<IStandardMonthlyResponse[]>(
                `${ApiRoutesEnum.STANDARD_REPORT_MONTHLY}?pagination=false`,
                {
                    params: { ...queryParams },
                }
            )
        );

        return userResponse;
    }

    async getSigmaReport(queryParams: any): Promise<ISigmaResponse[]> {
        const userResponse = apiService.responseHandler(
            await apiService.get<ISigmaResponse[]>(
                `${ApiRoutesEnum.SIGMA_REPORT}?pagination=false`,
                {
                    params: { ...queryParams },
                }
            )
        );

        return userResponse;
    }
}

export const reportService = new ReportService();
