import { useState } from 'react';
import {
    getItemFromLocalStorage,
    setItemToLocalStorage,
} from 'shared/utils/localStorageHandler';
import { LocalStorageKeysEnum } from 'shared/enums/LocalStorageKeysEnum';

type LocalStorage = [any, (value: any) => void];

export const useLocalStorage = (
    key: LocalStorageKeysEnum,
    initialValue: any
): LocalStorage => {
    const [storedValue, setStoredValue] = useState(
        () => getItemFromLocalStorage(key) ?? initialValue
    );

    const setValue = (value: any) => {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
            value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);
        setItemToLocalStorage(key, valueToStore);
    };

    return [storedValue, setValue];
};
