import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// import { persistQueryClient } from "react-query/persistQueryClient-experimental";
// import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { QueryClient } from 'react-query';
import { AppContextProvider } from 'shared/context/AppContextProvider';
import { TimeDurationEnum } from 'shared/enums/TimeDurationEnum';
// import 'shared/utils/multiLanguageSupport';
import App from './App';
import { reportWebVitals } from './reportWebVitals';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: TimeDurationEnum.HALF_DAY,
            refetchOnWindowFocus: false,
            // retry: true,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={null}>
            <AppContextProvider>
                <App />
            </AppContextProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
