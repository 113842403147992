import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'shared/components/Loading/Loading';
// import { PublicRoute } from 'shared/components/Routes';
import { Navigation } from '../../navigation/components/Navigation';
import { NavigationItems } from '../../navigation/constants/NavigationItems';
import { useUser } from '../hooks/useUser';
import styles from './styles.module.scss';

const LayoutPage = () => {
    const { isLoading } = useUser();

    return (
        <main className={styles.mainContainer}>
            {isLoading ? (
                <Loading height={500} />
            ) : (
                <>
                    <div className={styles.NavigationWrapper}>
                        <Navigation />
                    </div>
                    <div className={styles.pageContentWrapper}>
                        <Switch>
                            {/* <Redirect exact from={ROUTES.HOME} to={ROUTES.STUDENTS}/> */}
                            {NavigationItems.map(
                                ({ path, exactPath, Component }) => (
                                    <Route
                                        key={path}
                                        exact={exactPath}
                                        path={path}
                                        component={Component}
                                    />
                                )
                            )}
                            <Redirect from="/" to={ROUTES.HOME} />
                        </Switch>
                    </div>
                </>
            )}
        </main>
    );
};

export { LayoutPage };
