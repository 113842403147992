import { useUser } from 'modules/layout/hooks/useUser';
import { useSigmaReport } from 'modules/sigmaReport/hooks/useSigmaReport';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ExportButton from 'shared/components/Button/ExportButton';
import Loading from 'shared/components/Loading/Loading';
import Table, { ITableHeaders } from 'shared/components/Table/Table';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { dollarConverter } from 'shared/utils/currencyFormater';

import styles from './styles.module.scss';

interface ISigmaReportTableProps {
    searchParams: {
        organization: number;
        facility: number;
        code: number;
        year: number;
    };
    headers: ITableHeaders[];
    orderByTableHeader?: (index: number) => void;
}

export default function SigmaReportTable({
    searchParams,
    headers,
    orderByTableHeader,
}: ISigmaReportTableProps) {
    const [tableData, setTableData] = useState([]);

    const { user } = useUser();

    const {
        data: SigmaReportData,
        isLoading,
        refetch,
    } = useSigmaReport({ ...searchParams, headers });

    useEffect(() => {
        // refetch procedure with sorting order from table headers
        // happens only when user click it
        if (tableData.length) {
            headers.forEach((header) => {
                if (header.selected) {
                    refetch(); // refetch when header change
                }
            });
        }
    }, [headers]);

    useEffect(() => {
        // console.log('effect', ProceduresData);
        const tempTableData: any = [];

        switch (user.role) {
            case UserRoleEnum.ROLE_ZILIUS_ADMIN:
            case UserRoleEnum.ROLE_ORGANIZATION_QUALITY_OFFICER:
            case UserRoleEnum.ROLE_CARE_OPERATOR:
                SigmaReportData?.forEach((report) => {
                    tempTableData.push({
                        data: [
                            report.year,
                            moment(report.month, 'MM').format('MMMM'),
                            report.user.facilities[0].organization.name,
                            report.user.facilities[0].name,
                            report.procedure.ms_diagnostic_related_group.code,
                            report.user.full_name,
                            report.procedure.code,
                            report.sigma.toFixed(2),
                            report.sigma_shift.toFixed(2),
                            report.discharges,
                            report.alerts,
                            report.readmits_expected.toFixed(),
                            report.readmits,
                            (report.readmit * 100).toFixed(2) + '%',
                            dollarConverter.format(report.readmits_cost),
                            dollarConverter.format(
                                report.readmits_cost_avoided
                            ),
                            dollarConverter.format(report.cumulative_savings),
                        ],
                        // actions: {
                        //     onClick: () =>
                        //         rowClickHandler(procedure.id.toString()),
                        // },
                    });
                });

                setTableData(tempTableData);
                break;

            default:
                break;
        }
    }, [SigmaReportData]);

    return (
        <>
            {isLoading ? (
                <Loading height={300} />
            ) : (
                <>
                    <div className={styles.ButtonsPlace}>
                        <ExportButton
                            title="Export"
                            exportData={tableData}
                            headers={headers}
                            fileName="Sigma_Report"
                            style={styles.Button}
                        />
                    </div>
                    <Table
                        data={{
                            headers,
                            row: tableData,
                        }}
                        orderByTableHeader={orderByTableHeader}
                    />
                </>
            )}
        </>
    );
}
