import styles from './styles.module.scss';
import { NavigationItems } from 'modules/navigation/constants/NavigationItems';
import { NavLink, useHistory } from 'react-router-dom';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { NavigationItem } from '../NavigationItem';

import logo from '../../../../assets/images/zilius-full.png';
import { ReactComponent as Menu } from '../../../../assets/icons/Menu.svg';
import { ReactComponent as CloseButton } from '../../../../assets/icons/closeButton.svg';
import { ReactComponent as Person } from '../../../../assets/icons/Person.svg';
import { ReactComponent as Settings } from '../../../../assets/icons/Settings.svg';

import { useState } from 'react';
import UserManagement from 'modules/UserManagement/pages/UserManagement';
import { queryClient } from 'index';
import { IUser } from 'shared/interfaces/user/IUser';
import { UserRoleEnum } from 'shared/enums/UserRoleEnum';
import { userService } from 'shared/services/UserService';

export const Navigation = () => {
    const { push } = useHistory();
    const [openMenu, setOpenMenu] = useState(false);

    const user: IUser = queryClient.getQueryData('user');

    const handleLogout = () => {
        userService.logout();
        push(ROUTES.LOGIN);
    };

    return (
        <div className={styles.appHeader}>
            <div className={styles.Navigation}>
                <div
                    className={styles.hamburgerIcon}
                    onClick={() => setOpenMenu((prev) => !prev)}
                >
                    <Menu />
                </div>
                <div className={styles.Logo}>
                    <NavLink to={ROUTES.HOME}>
                        <img src={logo} alt="" />
                    </NavLink>
                </div>

                <div className={styles.Links}>
                    {NavigationItems.map((navItem) =>
                        navItem.showInMenu ? (
                            <NavigationItem key={navItem.path} item={navItem} />
                        ) : null
                    )}
                </div>
            </div>
            <div className={styles.Actions}>
                {user?.role === UserRoleEnum.ROLE_ZILIUS_ADMIN ? (
                    <div
                        className={styles.Event}
                        onClick={() => push(ROUTES.ADMIN_DASHBOARD)}
                    >
                        <Settings />
                        <span>User management</span>
                    </div>
                ) : null}

                <div className={styles.Event}>
                    <Person />
                    <span>{user?.fullName}</span>
                    <div className={styles.Dropdown} onClick={handleLogout}>
                        <span>Log out</span>
                    </div>
                </div>
            </div>

            {openMenu ? (
                <div className={styles.Mobile}>
                    <div
                        className={styles.Icon}
                        onClick={() => setOpenMenu((prev) => !prev)}
                        style={{ marginBottom: '30px' }}
                    >
                        <CloseButton />
                    </div>
                    <div className={styles.LinksMobile}>
                        {NavigationItems.map((navItem) =>
                            navItem.showInMenu ? (
                                <NavigationItem
                                    key={navItem.path}
                                    item={navItem}
                                />
                            ) : null
                        )}

                        <NavigationItem
                            item={{
                                path: ROUTES.ADMIN_DASHBOARD,
                                exactPath: true,
                                translateKey: 'User management',
                                Component: UserManagement,
                            }}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};
