import { useToastContext } from './context/ToastContext';

import { ReactComponent as CloseIcon } from '../../../assets/icons/closeButton.svg';

// CSS modules
import styles from './Toast.style.module.scss';
import { cssClassNameFormatter } from 'shared/utils/cssClassNameFormatter';

export default function Toast(): JSX.Element {
    const { show, closeToast, type, text } = useToastContext();

    return (
        <>
            {show ? (
                <div
                    className={cssClassNameFormatter([
                        styles.globalToast,
                        type === 'success' ? styles.success : styles.error,
                    ])}
                >
                    <p>{text}</p>
                    <CloseIcon
                        className={styles.toastCloseIcon}
                        onClick={closeToast}
                    />
                </div>
            ) : null}
        </>
    );
}
