import { useMutation, useQueryClient } from 'react-query';
import { useToastContext } from 'shared/components/Toast/context/ToastContext';
import { IUser } from 'shared/interfaces/user/IUser';
import { userService } from 'shared/services/UserService';
import { ICreateNewPasswordCredentials } from '../interfaces/ISignInCredentials';

export const useResetPassword = () => {
    const queryClient = useQueryClient();
    const { showToast } = useToastContext();

    const { mutateAsync, isError, isLoading } = useMutation(
        (credentials: ICreateNewPasswordCredentials) => {
            const userID = queryClient.getQueryData<IUser>('user');
            return userService.resetPassword(+userID?.id, credentials);
        },

        {
            onError: (error: any) => {
                showToast('error', error?.detail);
                console.log('Error on reset password request - ', error);
            },
        }
    );

    return {
        resetPassword: mutateAsync,
        isLoading,
        error: {
            isError,
        },
    };
};
