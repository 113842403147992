import { useQuery, useQueryClient } from 'react-query';
import { TimeDurationEnum } from 'shared/enums/TimeDurationEnum';
import { filtersService } from 'shared/services/FiltersService';

export const useMSDRGCodes = () => {
    const queryClient = useQueryClient();
    const { data, isLoading, isError, refetch } = useQuery(
        ['MS DRG Codes'],
        () => filtersService.getMSDRGCode(),
        {
            onError: (error: Error) => {
                console.log('Error on getting MS DRG Codes - ', error);
            },
            initialData: () => queryClient.getQueryData(['MS DRG Codes']),
            enabled: Boolean(queryClient.getQueryData(['organizations'])),
            staleTime: TimeDurationEnum.HALF_DAY,
        }
    );

    return {
        data,
        isLoading,
        serverErrorMessage: isError,
        refetch,
    };
};
