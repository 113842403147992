import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { IRouteLocationState } from 'shared/interfaces/IRouteLocationState';
import { userService } from 'shared/services/UserService';

interface PublicRouteProps extends RouteProps {
    restricted?: boolean;
}

export const PublicRoute: FC<PublicRouteProps> = ({
    restricted = false,
    component: Component,
    children,
    ...rest
}) => {
    const userToken = userService.getToken();

    return (
        <Route
            {...rest}
            render={(routeProps) => {
                const locationState: IRouteLocationState = {
                    from: {
                        pathname: routeProps.location.pathname,
                    },
                    info: null,
                };

                return userToken && restricted ? (
                    <Redirect
                        to={{
                            pathname: ROUTES.HOME,
                            state: locationState,
                        }}
                    />
                ) : (
                    children ?? <Component {...routeProps} />
                );
            }}
        />
    );
};
