// Returns string of classNames separated by empty space
export function cssClassNameFormatter(classNames: string[]) {
    if (!classNames?.length) {
        return '';
    }

    return classNames
        .filter((className) => className)
        .map((className) => className.trim())
        .join(' ');
}
