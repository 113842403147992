import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { useToastContext } from 'shared/components/Toast/context/ToastContext';
import { LocalStorageKeysEnum } from 'shared/enums/LocalStorageKeysEnum';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { IRouteLocationState } from 'shared/interfaces/IRouteLocationState';
import { TUser } from 'shared/interfaces/user/IUser';
import { userService } from 'shared/services/UserService';
import { isValidRoute } from 'shared/utils/routeValidator';
import { ISignInCredentials } from '../interfaces/ISignInCredentials';

export const useLogin = () => {
    const { push } = useHistory();
    const { state } = useLocation<IRouteLocationState>();
    const [userToken, setUserToken] = useLocalStorage(
        LocalStorageKeysEnum.token,
        null
    );

    const queryClient = useQueryClient();

    const { showToast } = useToastContext();

    const { mutateAsync, isError, isLoading } = useMutation(
        (data: ISignInCredentials) => userService.login(data),
        {
            onSuccess: (userData: TUser) => {
                const { token, refreshToken, user } = userData;

                setUserToken({ token, refreshToken });

                queryClient.setQueryData('token', userToken);
                queryClient.setQueryData('user', user);

                // showToast('success', 'Login successfull!');

                push({
                    pathname: isValidRoute(state?.from?.pathname)
                        ? state.from.pathname
                        : ROUTES.HOME,
                });
            },
            onError: (error: any) => {
                console.log('Error on login user - ', error);
                showToast('error', error?.detail);
            },
        }
    );

    return {
        login: mutateAsync,
        isLoading,
        error: {
            isError,
        },
    };
};
