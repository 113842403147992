import React from 'react';
import styles from './styles.module.scss';

import { ReactComponent as Scalpel } from '../../../assets/icons/Scalpel.svg';
import { ReactComponent as Assignment } from '../../../assets/icons/Assignment.svg';
import { ReactComponent as Sigma } from '../../../assets/icons/Sigma.svg';
import Box from '../components/Box/Box';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'modules/navigation/enums/RoutesEnum';

export default function HomeScreen() {
    const { push } = useHistory();

    return (
        <div className={styles.HomeContainer}>
            <Box
                Icon={Scalpel}
                text="Procedures"
                onClick={() => push(ROUTES.PROCEDURES)}
            />
            <Box
                Icon={Assignment}
                text="Standard report"
                onClick={() => push(ROUTES.STANDARD_REPORT)}
            />
            <Box
                Icon={Sigma}
                text="Sigma report"
                onClick={() => push(ROUTES.SIGMA_REPORT)}
            />
        </div>
    );
}
