import styles from './styles.module.scss';

import ContentTitle from 'shared/components/ContentTitle/ContentTitle';

import DataView from '../components/dataView/DataView';
import { useState } from 'react';
import MonthlyPerformance from '../components/monthlyPerformance/MonthlyPerformance';

export default function StandardReport() {
    const [screen, setScreen] = useState('data');

    return (
        <div>
            <div className={styles.ContentTitle}>
                <ContentTitle title="Standard report" />

                {/* <ExportButton title="Download" /> */}
            </div>

            <div className={styles.contentMenu}>
                <ul>
                    <li
                        className={screen === 'data' ? styles.active : null}
                        onClick={() => setScreen('data')}
                    >
                        Data
                    </li>
                    <li
                        className={screen === 'monthly' ? styles.active : null}
                        onClick={() => setScreen('monthly')}
                    >
                        Monthly Performance
                    </li>
                </ul>
            </div>

            {screen === 'data' ? <DataView /> : <MonthlyPerformance />}
        </div>
    );
}
