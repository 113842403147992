import { FC } from 'react';

import Portal from './Portal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/closeButton.svg';

// CSS modules
import ModalStyles from './modal.module.scss';

interface IModal {
    close: () => void;
    render: any;
    title: string;
    icon?: string;
}

const Modal: FC<IModal> = ({ children, close, render, title }) => {
    return (
        <Portal>
            <div className={ModalStyles.modalCenterRoot}>
                <div className={ModalStyles.modalCenterWrapper}>
                    <div className={ModalStyles.modalHeader}>
                        <div className={ModalStyles.modalTitle}>
                            <h4>{title}</h4>
                        </div>
                        <CloseIcon
                            className={ModalStyles.modalCloseIcon}
                            onClick={close}
                        />
                    </div>
                    {render(children) || children}
                </div>
                <div className={ModalStyles.modalBackdrop} onClick={close} />
            </div>
        </Portal>
    );
};

export default Modal;
