import React from 'react';
import styles from './styles.module.scss';

import moment from 'moment';

import { CSVLink } from 'react-csv';
import { ITableHeaders } from '../Table/Table';
import { cssClassNameFormatter } from 'shared/utils/cssClassNameFormatter';

interface IExportButton {
    title: string;
    exportData: { data: string[]; actions: any }[];
    fileName: string;
    headers: ITableHeaders[];
    style: any;
}

export default function ExportButton({
    title,
    exportData,
    fileName,
    headers,
    style,
}: IExportButton) {
    return (
        <CSVLink
            data={[
                headers.map((header) => header.title),
                ...exportData.map(({ data }) => data),
            ]}
            filename={`${fileName}_${moment().format(
                'MM/DD/YYYY_HH:mm:ss'
            )}.csv`}
            className={cssClassNameFormatter([styles.exportButton, style])}
        >
            {title}
        </CSVLink>
    );
}
