import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'shared/components/Button/Button';
import { FormInput } from 'shared/components/FormInputs/FormInputs';

import logo from '../../../../assets/images/zilius-full.png';
import styles from '../Login/styles.module.scss';
import { useResetPasswordRequest } from 'modules/user/hooks/useResetPasswordRequest';

export default function ForgotPassword() {
    const { requestPasswordReset, isLoading } = useResetPasswordRequest();
    const { handleSubmit, handleChange, values, errors, touched } = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email format')
                .required('Email is required'),
        }),
        onSubmit: async (values) => {
            if (await requestPasswordReset(values)) {
                //call magic link get
            }
        },
    });

    return (
        <div className={styles.LoginScreen}>
            <form onSubmit={handleSubmit}>
                <img src={logo} alt="Zilius-logo-image" />
                <span>
                    Please enter your email address so we can send you an email
                    to reset your password.
                </span>
                <FormInput
                    id="email"
                    label="Email"
                    required
                    handleChange={handleChange}
                    value={values.email}
                    hasError={touched.email && errors.email}
                    errorMsg={errors.email}
                />

                <div className={styles.FormButtons}>
                    <Button
                        title="Send email"
                        type="submit"
                        fill
                        className={styles.SubmitButton}
                        loading={isLoading}
                    />

                    {/* <Button title="Cancel" /> */}
                </div>
            </form>
        </div>
    );
}
