import { INavigationItem } from 'modules/navigation/interfaces/navigation-item.interface';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

interface IProps {
    item: INavigationItem;
}

const NavigationItem = ({ item }: IProps) => {
    const { translateKey, path } = item;
    return (
        <NavLink to={path} activeClassName={styles.active}>
            {translateKey}
        </NavLink>
    );
};

export { NavigationItem };
