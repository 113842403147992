import { ROUTES } from 'modules/navigation/enums/RoutesEnum';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useToastContext } from 'shared/components/Toast/context/ToastContext';
import { userService } from 'shared/services/UserService';
import { IAssignUser } from '../interfaces/IAssignUser';

export const useAssignUser = () => {
    const { push } = useHistory();
    const { showToast } = useToastContext();

    const { mutateAsync, isError, isLoading } = useMutation(
        (data: IAssignUser) => userService.assignUser(data),
        {
            onSuccess: () => {
                push(ROUTES.ADMIN_DASHBOARD);
            },
            onError: (error: any) => {
                console.log('Error on login user - ', error?.detail);
                showToast('error', error?.detail);
            },
        }
    );

    return {
        mutateAsync,
        isLoading,
        error: {
            isError,
        },
    };
};
