import { LocalStorageKeysEnum } from 'shared/enums/LocalStorageKeysEnum';

export function getItemFromLocalStorage(key: LocalStorageKeysEnum) {
    try {
        const item = window.localStorage.getItem(key);

        return item ? JSON.parse(item) : null;
    } catch (error) {
        console.error(
            `Can not get item with key ${key} from local storage - `,
            error
        );
    }
}

export function setItemToLocalStorage(key: LocalStorageKeysEnum, value: any) {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error(
            `Can not set item with key ${key} to local storage - `,
            error
        );
    }
}

export function clearLocalStorage() {
    try {
        window.localStorage.clear();
    } catch (error) {
        console.error('Error on local storage clear function - ', error);
    }
}
